module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:100,300,400,500"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":300,"showCaptions":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"bodyOpenClassName":null,"closeTimeoutMS":300,"styles":{"overlay":null,"content":null}}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
