/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('normalize.css')
require("./src/styles/index.scss");

const { library } = require('@fortawesome/fontawesome-svg-core');
const { fab } = require('@fortawesome/free-brands-svg-icons');
const { faAdjust, faTimes } = require('@fortawesome/free-solid-svg-icons');

library.add(fab, faAdjust, faTimes)
